<template>
  <!-- 商城-积分商品-商品详情 -->
  <div class="content-page">
    <!-- 导航栏 -->
    <b-card>
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/webstore' }">Webstore</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/pointsmerchandise' }">All Products</el-breadcrumb-item>
          <el-breadcrumb-item>Product Page</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 商品详情-----积分商品 -->
      <div class="row pb-3" v-if="pointsProductDetails.points_type==0">
        <div class="col-md-6 col-sm-12">
          <div class="row big_banner">
            <div class="col-3 text-center banner_img">
              <swiper class="swiper" :options="swiperOption" ref="mySwiper">
                <swiper-slide v-for="(v, i) in pointsProductDetails.product_gallery" :key="i">
                  <img class="w-75 cursor-pointer" alt="" :src="v" @click="activeImg=v" />
                </swiper-slide>
              </swiper>
              <div v-show="pointsProductDetails.product_gallery&&pointsProductDetails.product_gallery.length>3">
                <i class="ri-arrow-down-s-line swiper-next" slot="button-next"
                  style="font-size: 20px;cursor: pointer;"></i>
              </div>
            </div>
            <div class="col-9 p-0">
              <img class="w-100" :src="activeImg" alt="" />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 pl-3 container_shop">
          <h3>{{pointsProductDetails.product_name}}</h3>
          <div class="pt-2">
            <div class="share">
              <ShareBtn style="z-index: 99999" :shareData="pointsProductDetails" shareType="8" />
            </div>
          </div>
          <div class="line"></div>
          <div class="row">
            <p class="col-3 title pt-2 m-0">Price</p>
            <p class="col-9 color-red m-0">{{selectPrice.id?
              selectPrice.points:display?pointsProductDetails.points_range:pointsProductDetails.points}}
              Points</p>
          </div>
          <div class="row">
            <div class="col-3 title">Freight</div>
            <div class="col-5 pl-2 text-detail">Free Shipping</div>
          </div>
          <div class="line"></div>
          <div class="row pb-3" v-for="(item, index) in pointsProductDetails.attr_list" :key="index">
            <p class="col-3 title">{{item.attr_name}}</p>
            <div class="col-9">
              <button type="button" :class="['square',{'square-active':v.select},{'square-disabled':!v.is_use}]"
                v-for="(v, i) in item.attr_list" :key="i" :disabled="!v.is_use"
                @click="selectValue(v,item.attr_list)">{{v.attr_value}}</button>
            </div>
          </div>
          <div class="row pb-3">
            <p class="col-3 title">Quantity</p>
            <p class="col-9 quantity">
              <button class="square w_30" @click="minus" :disabled="number==1">-</button>
              <input class="text-center square square-active w_30" type="text" v-model="number" @input=limitValue() />
              <button class="square w_30" @click="add">+</button>
            </p>
          </div>
          <button class="btn-active grey_btn" disabled v-if="soldOut">Sold out</button>
          <button class="btn-active" @click="pointsOrderConfirmation" v-else>Exchange</button>
        </div>
      </div>

      <!-- 商品详情-----贴纸 -->
      <div class="pb-3 emoji_container" v-if="pointsProductDetails.points_type==1">
        <div class="row content_emoji">
          <div class="col-md-5 col-sm-12 text-right">
            <img class="emoji_img" :src="pointsProductDetails.product_image" alt="" />
          </div>
          <div class="col-md-7 col-sm-12 pl-3 container_shop">
            <h3>{{pointsProductDetails.product_name}}</h3>
            <div class="pt-2">
              <div class="share">
                <ShareBtn style="z-index: 99999" :shareData="pointsProductDetails" shareType="8" />
              </div>
            </div>
            <div class="line"></div>
            <div class="mt-5">
              <p class="color-red">{{pointsProductDetails.points}} Points</p>
            </div>
            <button class="btn-active mt-5" :class="{'grey_btn':pointsProductDetails.is_buy}" @click="emojiExchange"
              :disabled="pointsProductDetails.is_buy==1">Exchange</button>
          </div>
        </div>
      </div>
    </b-card>

    <b-card style="margin-top: 32px;">
      <ul class="nav nav-tabs pb-1" id="myTab" role="tablist">
        <li class="nav-item nav-active" role="presentation">
          <a class="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
            aria-selected="true">Recommend Details</a>
        </li>
      </ul>
    </b-card>

    <b-card class="mt-3">
      <div class="tab-content mt-3 p-2" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <div v-if="pointsProductDetails.introduction" v-html="pointsProductDetails.introduction" class="introduction">
          </div>
          <div style="background:#fff;" class="d-flex justify-content-center align-items-center py-5" v-else>
            <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import ShareBtn from "@/components/ComComponents/ShareBtn.vue";
  export default {
    // 局部注册组件
    components: {
      Swiper,
      SwiperSlide,
      ShareBtn
    },
    data() {
      return {
        number: 1,
        pointsProductDetails: {},
        activeImg: '',
        selectPrice: {},
        id: '',
        swiperOption: {
          slidesPerView: 4,
          slidesPerColumn: 1,
          direction: "vertical",
          spaceBetween: 10,
          nextButton: '.swiper-next',
        },
        display: false,
        soldOut: false
      };
    },

    created() {
      this.id = this.$route.query.id
      this._pointsProductDetails()
    },

    methods: {
      add() {
        if (this.selectPrice.stock) {
          if (this.number < this.selectPrice.stock) {
            this.number++;
          } else {
            this.$message.warning('No quantity available in stock')
          }
        } else {
          this.number++;
        }
      },

      minus() {
        if (this.number > 1) {
          this.number--;
        }
      },

      limitValue() {
        if (this.number === '0') return this.number = 1
        if (/[^0-9]/g.test(this.number)) {
          this.$message.warning("Only numbers can be entered")
        }
        this.number = this.number.replace(/[^0-9]/g, "");
        if (this.number > this.selectPrice.stock) {
          this.$message.warning('Inventory shortage')
          this.number = 1
        }
      },

      // 积分商品详情
      async _pointsProductDetails() {
        let res = await this.$http.pointsProductDetails({ id: this.id })
        if (res.status == 200) {
          this.pointsProductDetails = res.data
          if (this.pointsProductDetails.points_type == 1) return
          if (!this.pointsProductDetails.attr_price.length) {
            this.soldOut = true
          }
          this.activeImg = this.pointsProductDetails.product_gallery[0]
          this._handlerValue()
        }
      },

      // 选择商品属性
      selectValue(v, attr_list) {
        if (v.select == 1) {
          attr_list.map(item => {
            item.select = 0
          })
          this.selectPrice = {}
        } else {
          attr_list.map(item => {
            item.select = 0
          })
          v.select = 1
          this.number = 1
        }
        this._handlerValue()
      },

      _handlerValue() {
        let arrayVal = []
        this.pointsProductDetails.attr_list.forEach(ele => {
          ele.attr_list.forEach(vv => {
            if (vv.select) arrayVal.push(String(vv.product_attr_id))
          })
        })

        this.notSelect = true
        if (arrayVal.length != this.pointsProductDetails.attr_price[0].product_attr.length) return

        this.notSelect = false
        for (let i = 0; i < this.pointsProductDetails.attr_price.length; i++) {
          const ele = this.pointsProductDetails.attr_price[i];
          if (JSON.stringify(arrayVal.sort()) === JSON.stringify(ele.product_attr.sort())) {
            // console.log(ele);
            this.selectPrice = ele
            return
          } else {
            this.display = true
            this.selectPrice = {}
          }
        }
      },

      async pointsOrderConfirmation() {
        if (this.notSelect) return this.$message.warning('Please select product attributes !')
        if (!this.selectPrice.id) return this.$message.warning('Inventory shortage !')
        let data = {
          id: this.id,
          attr_price_id: this.selectPrice.id,
          quantity: this.number
        }
        let res = await this.$http.pointsOrderConfirmation(data)
        if (res.status == 200) {
          this.$router.push(`/scoreplaceorder?id=${this.id}`)
          sessionStorage.setItem('order_detail', JSON.stringify(res.data))
        }
      },

      emojiExchange() {
        this.$confirm("Are you sure about the exchange?", "tips", {
          distinguishCancelAndClose: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        })
          .then(async () => {
            let data = {
              id: this.id,
              price: this.pointsProductDetails.points,
              time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            let res = await this.$http.emojiExchange(data)
            if (res.status == 200) {
              this.$router.push(`/exchangeResult?type=1`)
            } else {
              this.$router.push(`/exchangeResult?type=2&msg=${res.message}`)
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Exchange cancelled",
            });
          })
      }
    },
  };
</script>
<style lang="scss" scoped>
  .breadcrumb {
    height: 60px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    background-color: #fff;

    ::v-deep span.el-breadcrumb__inner.is-link {
      color: #50B5FF;
      font-weight: 500;
    }
  }

  div.share {
    color: #50b5ff;

    ::v-deep .shareOption {
      left: 0;
    }
  }

  .big_banner {
    img {
      height: 410px;
    }
  }

  .banner_img {
    img {
      height: 81px;
      object-fit: cover;
    }
  }

  .swiper-container {
    height: 375px;
  }

  .square {
    min-width: 30px;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 2px;
    color: #333;
    background: #fff;
  }

  .w_30 {
    width: 30px;
  }

  .square-active {
    border: 1px solid #50b5ff;
    color: #50b5ff;
  }

  .square-disabled {
    background-color: #ededed;
  }

  .title {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-family: "poppins", "montserra";
    font-weight: 400;
    color: #999999;
    display: flex;
    align-items: center;
  }

  .quantity {
    margin: 0;
  }

  .color-red {
    font-size: 24px;
    font-family: Poppins Medium, Poppins Medium-Medium;
    font-weight: 500;
    color: #ef5959;
  }

  .grey_btn {
    background-color: #999 !important;
    border-color: #999 !important;
  }

  .text-detail {
    font-size: 14px;
    font-family: "poppins", "montserra";
    font-weight: 400;
    color: #333333;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #eee;
    margin: 15px 0;
  }

  .btn-active {
    width: 160px;
    height: 45px;
    color: #fff;
    background: #50b5ff;
    border: 1px solid #50b5ff;
  }

  .card-body {
    padding: 0;
  }

  .label {
    width: 100%;
    height: 80px;
    padding: 15px;
    background: #f5fbff;
    border-radius: 5px;
  }

  #myTab {
    padding: 10px 20px 0 20px;
    margin-bottom: -2px;

    .nav-item {
      .nav-link {
        padding: 8px 0;
      }
    }

    .nav-active {
      border-bottom: 2px solid #50b5ff;
    }
  }

  .introduction {
    min-height: 300px;
    padding: 0 10px;

    ::v-deep img {
      display: block;
    }
  }

  .emoji_container {
    display: flex;
    justify-content: center;

    .content_emoji {
      width: 836px;
    }

    .emoji_img {
      width: 302px;
      height: 302px;
    }
  }
</style>